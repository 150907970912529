import {
  BooleanField,
  Datagrid,
  Edit,
  NumberField,
  ReferenceField,
  ResourceProps,
  TextField,
} from 'react-admin'
import { EGroupsFields } from './Groups.type'
import {
  InputBoolean,
  InputNumber,
  InputText,
  InputWysiwyg,
} from 'src/components/Input'
import { TruncatedTextField } from 'src/components/Field'
import { EResources } from 'src/types/resource.types'
import Form from '../../components/Form/Form'
import { EMethod } from '../../hooks/useShowApiErrors'
import SelectFilter from './components/SelectFilter/SelectFilter'
import MyList from '../../components/MyList/MyList'
import { MIN_TRUNCATE_LIMIT } from '../../components/Field/TruncatedTextField/TruncatedTextField.const'

const groupsFilters = [
  <SelectFilter
    source={EGroupsFields.grupa_id_nad}
    url={EResources.grupas}
    alwaysOn
  />,
]

const GroupsList = () => (
  <MyList filters={groupsFilters}>
    <Datagrid rowClick='edit'>
      <TextField source={EGroupsFields.grupa_id} />
      <TextField source={EGroupsFields.nazwa_st} />
      <TruncatedTextField
        source={EGroupsFields.opis_st}
        limit={MIN_TRUNCATE_LIMIT}
      />
      <NumberField source={EGroupsFields.sort} />
      <TextField source={EGroupsFields.uwagi} />
      <BooleanField source={EGroupsFields.czy_grupakat} />
      <BooleanField source={EGroupsFields.czy_branza} />
      <BooleanField source={EGroupsFields.czy_oferta} />
      <BooleanField source={EGroupsFields.czy_firma} />
      <BooleanField source={EGroupsFields.is_visible} />

      <ReferenceField
        reference={EResources.grupas}
        source={EGroupsFields.grupa_id_nad}
      >
        <TextField source={EGroupsFields.nazwa_out} />
      </ReferenceField>

      <TextField source={EGroupsFields.slug} />
      <TruncatedTextField
        source={EGroupsFields.seo_text_meta}
        limit={MIN_TRUNCATE_LIMIT}
      />
      <TruncatedTextField
        source={EGroupsFields.seo_title}
        limit={MIN_TRUNCATE_LIMIT}
      />
      <TruncatedTextField
        source={EGroupsFields.meta_description}
        limit={MIN_TRUNCATE_LIMIT}
      />
      <TruncatedTextField
        source={EGroupsFields.description}
        limit={MIN_TRUNCATE_LIMIT}
      />

      <TextField source={EGroupsFields.default_availability_period} />
    </Datagrid>
  </MyList>
)

const GroupsEdit = () => (
  <Edit>
    <Form resource={EResources.grupas} method={EMethod.PUT}>
      <InputText source={EGroupsFields.grupa_id} />
      <InputText source={EGroupsFields.nazwa_st} />
      <InputText source={EGroupsFields.opis_st} />
      <InputNumber source={EGroupsFields.sort} />
      <InputText source={EGroupsFields.uwagi} />
      <InputBoolean source={EGroupsFields.czy_grupakat} />
      <InputBoolean source={EGroupsFields.czy_branza} />
      <InputBoolean source={EGroupsFields.czy_oferta} />
      <InputBoolean source={EGroupsFields.czy_firma} />
      <InputBoolean source={EGroupsFields.is_visible} />
      <InputNumber source={EGroupsFields.grupa_id_nad} />
      <InputNumber source={EGroupsFields.default_availability_period} />
      <InputText source={EGroupsFields.slug} />
      <InputText source={EGroupsFields.seo_text_meta} />
      <InputText source={EGroupsFields.seo_title} />
      <InputText source={EGroupsFields.meta_description} />
      <InputWysiwyg source={EGroupsFields.description} />
    </Form>
  </Edit>
)

export const grupas: ResourceProps = {
  name: EResources.grupas,
  list: GroupsList,
  edit: GroupsEdit,
}
