import { Datagrid, Edit, ResourceProps, TextField } from 'react-admin'
import { EResources } from '../../types/resource.types'
import { ESystemConfigs } from './SystemConfigs.type'
import { InputText } from '../../components/Input'
import Form from '../../components/Form/Form'
import { EMethod } from '../../hooks/useShowApiErrors'
import MyList from '../../components/MyList/MyList'

const SystemConfigsList = () => {
  return (
    <MyList>
      <Datagrid rowClick='edit'>
        <TextField source={ESystemConfigs.id} />
        <TextField source={ESystemConfigs.name} />
        <TextField source={ESystemConfigs.value} />
      </Datagrid>
    </MyList>
  )
}

const SystemConfigEdit = () => {
  return (
    <Edit>
      <Form method={EMethod.PUT} resource={EResources.systemConfigs}>
        <InputText source={ESystemConfigs.name} />
        <InputText source={ESystemConfigs.value} />
      </Form>
    </Edit>
  )
}

export const systemConfigs: ResourceProps = {
  name: EResources.systemConfigs,
  list: SystemConfigsList,
  edit: SystemConfigEdit,
}
