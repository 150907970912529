import { useListContext } from 'react-admin'
import { Button } from '@mui/material'
import ContentFilter from '@mui/icons-material/FilterList'

const MyFilterButton = () => {
  const { showFilter, displayedFilters, hideFilter } = useListContext()
  return (
    <Button
      size='small'
      sx={{
        marginRight: 'auto',
      }}
      color='primary'
      onClick={() =>
        !displayedFilters.main ? showFilter('main', {}) : hideFilter('main')
      }
      startIcon={<ContentFilter />}
    >
      {!displayedFilters.main ? 'Pokaz filtry' : 'Ukryj filtry'}
    </Button>
  )
}

export default MyFilterButton
