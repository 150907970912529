import {
  BooleanField,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  ResourceProps,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin'
import { EResources } from '../../types/resource.types'
import { EInquiriesFields } from './Inquiries.type'
import { InputBoolean, InputText } from '../../components/Input'
import Form from '../../components/Form/Form'
import { EMethod } from '../../hooks/useShowApiErrors'
import MyList from '../../components/MyList/MyList'

const InquiriesList = () => (
  <MyList>
    <Datagrid rowClick={'show'}>
      <TextField source={EInquiriesFields.id} />
      <TextField source={EInquiriesFields.additional_id} />
      <TextField source={EInquiriesFields.topic} />
      <TextField source={EInquiriesFields.slug} />
      <TextField source={EInquiriesFields.user_id} />
      <TextField source={EInquiriesFields.company_id} />
      <TextField source={EInquiriesFields.description} />
      <TextField source={EInquiriesFields.type} />
      <TextField source={EInquiriesFields.quantity} />
      <TextField source={EInquiriesFields.different_details} />
      <TextField source={EInquiriesFields.acceptable_waiting_time} />
      <TextField source={EInquiriesFields.suggested_price} />
      <TextField source={EInquiriesFields.status} />
      <TextField source={EInquiriesFields.seo_title} />
      <TextField source={EInquiriesFields.deadline} />
      <TextField source={EInquiriesFields.visits_counter} />
      <TextField source={EInquiriesFields.seo_text_meta} />
      <DateField source={EInquiriesFields.modified_at} />
      <TextField source={EInquiriesFields.created_at} />
      <TextField source={EInquiriesFields.deleted_at} />
      <TextField source={EInquiriesFields.expires_at} />
      <BooleanField
        source={EInquiriesFields.visible_only_for_cluster_members}
      />
      <BooleanField source={EInquiriesFields.system_inquiry} />
    </Datagrid>
  </MyList>
)

const InquiriesShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source={EInquiriesFields.id} />
      <TextField source={EInquiriesFields.additional_id} />
      <TextField source={EInquiriesFields.topic} />
      <TextField source={EInquiriesFields.slug} />
      <TextField source={EInquiriesFields.user_id} />
      <TextField source={EInquiriesFields.company_id} />
      <TextField source={EInquiriesFields.description} />
      <TextField source={EInquiriesFields.type} />
      <TextField source={EInquiriesFields.quantity} />
      <TextField source={EInquiriesFields.different_details} />
      <TextField source={EInquiriesFields.acceptable_waiting_time} />
      <TextField source={EInquiriesFields.suggested_price} />
      <TextField source={EInquiriesFields.status} />
      <TextField source={EInquiriesFields.seo_title} />
      <TextField source={EInquiriesFields.deadline} />
      <TextField source={EInquiriesFields.visits_counter} />
      <TextField source={EInquiriesFields.seo_text_meta} />
      <DateField source={EInquiriesFields.modified_at} />
      <TextField source={EInquiriesFields.created_at} />
      <TextField source={EInquiriesFields.deleted_at} />
      <TextField source={EInquiriesFields.expires_at} />
      <BooleanField
        source={EInquiriesFields.visible_only_for_cluster_members}
      />
      <BooleanField source={EInquiriesFields.system_inquiry} />
    </SimpleShowLayout>
  </Show>
)

const InquiriesEdit = () => (
  <Edit>
    <Form resource={EResources.inquiries} method={EMethod.PUT}>
      <InputText source={EInquiriesFields.id} />
      <InputText source={EInquiriesFields.additional_id} />
      <InputText source={EInquiriesFields.topic} />
      <InputText source={EInquiriesFields.slug} />
      <InputText source={EInquiriesFields.user_id} />
      <InputText source={EInquiriesFields.company_id} />
      <InputText source={EInquiriesFields.description} />
      <InputText source={EInquiriesFields.type} />
      <InputText source={EInquiriesFields.quantity} />
      <InputText source={EInquiriesFields.different_details} />
      <InputText source={EInquiriesFields.acceptable_waiting_time} />
      <InputText source={EInquiriesFields.suggested_price} />
      <InputText source={EInquiriesFields.status} />
      <InputText source={EInquiriesFields.seo_title} />
      <DateInput source={EInquiriesFields.deadline} />
      <InputText source={EInquiriesFields.visits_counter} />
      <InputText source={EInquiriesFields.seo_text_meta} />
      <DateInput source={EInquiriesFields.modified_at} />
      <DateInput source={EInquiriesFields.created_at} />
      <DateInput source={EInquiriesFields.deleted_at} />
      <DateInput source={EInquiriesFields.expires_at} />
      <InputBoolean
        source={EInquiriesFields.visible_only_for_cluster_members}
      />
      <InputBoolean source={EInquiriesFields.system_inquiry} />
    </Form>
  </Edit>
)

export const inquiries: ResourceProps = {
  name: EResources.inquiries,
  list: InquiriesList,
  show: InquiriesShow,
  edit: InquiriesEdit,
}
