import { Datagrid, Edit, ResourceProps, TextField, Create } from 'react-admin'
import { EResources } from '../../types/resource.types'
import MyList from '../../components/MyList/MyList'
import { EAccountTypesFields } from './AccountTypes.type'
import Form from '../../components/Form/Form'
import { EMethod } from '../../hooks/useShowApiErrors'
import { InputNumber, InputText } from '../../components/Input'

const AccountTypesList = () => (
  <MyList hasCreate={true}>
    <Datagrid rowClick={'edit'}>
      <TextField source={EAccountTypesFields.name} />
      <TextField source={EAccountTypesFields.id} />
    </Datagrid>
  </MyList>
)

const AccountTypesEdit = () => (
  <Edit>
    <Form resource={EResources.accountTypes} method={EMethod.PUT}>
      <InputText source={EAccountTypesFields.name} />
      <InputNumber source={EAccountTypesFields.id} />
    </Form>
  </Edit>
)

const AccountTypesCreate = () => (
  <Create>
    <Form resource={EResources.accountTypes} method={EMethod.POST}>
      <InputText source={EAccountTypesFields.name} />
    </Form>
  </Create>
)

export const accountTypes: ResourceProps = {
  name: EResources.accountTypes,
  list: AccountTypesList,
  edit: AccountTypesEdit,
  create: AccountTypesCreate,
}
