import {
  Datagrid,
  DateField,
  Edit,
  ResourceProps,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin'
import { EResources } from '../../types/resource.types'
import { EAppReviewsFields } from './AppReviews.type'
import { InputDate, InputText } from '../../components/Input'
import Form from '../../components/Form/Form'
import { EMethod } from '../../hooks/useShowApiErrors'
import MyList from '../../components/MyList/MyList'

const AppReviewsList = () => (
  <MyList>
    <Datagrid rowClick={'show'}>
      <TextField source={EAppReviewsFields.id} />
      <TextField source={EAppReviewsFields.author_id} />
      <TextField source={EAppReviewsFields.company_id} />
      <TextField source={EAppReviewsFields.review} />
      <TextField source={EAppReviewsFields.rating} />
      <TextField source={EAppReviewsFields.position} />
      <TextField source={EAppReviewsFields.reply} />
      <DateField source={EAppReviewsFields.created_at} />
      <DateField source={EAppReviewsFields.updated_at} />
      <DateField source={EAppReviewsFields.replied_at} />
    </Datagrid>
  </MyList>
)

const AppReviewsShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source={EAppReviewsFields.id} />
      <TextField source={EAppReviewsFields.author_id} />
      <TextField source={EAppReviewsFields.company_id} />
      <TextField source={EAppReviewsFields.review} />
      <TextField source={EAppReviewsFields.rating} />
      <TextField source={EAppReviewsFields.position} />
      <TextField source={EAppReviewsFields.reply} />
      <DateField source={EAppReviewsFields.created_at} />
      <DateField source={EAppReviewsFields.updated_at} />
      <DateField source={EAppReviewsFields.replied_at} />
    </SimpleShowLayout>
  </Show>
)

const AppReviewsEdit = () => (
  <Edit>
    <Form method={EMethod.PUT} resource={EResources.appReviews}>
      <InputText source={EAppReviewsFields.id} />
      <InputText source={EAppReviewsFields.author_id} />
      <InputText source={EAppReviewsFields.company_id} />
      <InputText source={EAppReviewsFields.review} />
      <InputText source={EAppReviewsFields.rating} />
      <InputText source={EAppReviewsFields.position} />
      <InputText source={EAppReviewsFields.reply} />
      <InputDate source={EAppReviewsFields.created_at} />
      <InputDate source={EAppReviewsFields.updated_at} />
      <InputDate source={EAppReviewsFields.replied_at} />
    </Form>
  </Edit>
)

export const appReviews: ResourceProps = {
  name: EResources.appReviews,
  list: AppReviewsList,
  show: AppReviewsShow,
  edit: AppReviewsEdit,
}
