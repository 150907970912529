import {
  AutocompleteInput,
  Datagrid,
  DateField,
  DateInput,
  EditButton,
  NumberField,
  ReferenceField,
  ReferenceInput,
  ResourceProps,
  Show,
  SimpleShowLayout,
  TextField,
  NumberInput,
  TextInput,
} from 'react-admin'
import { EUsersFields } from './Users.type'
import { InputDate, InputNumber, InputText } from 'src/components/Input'
import { EResources } from 'src/types/resource.types'
import { ECompaniesFields } from 'src/lists/Companies/Companies.type'
import { companyReference, sortByCompany } from 'src/lists/Companies/Companies'
import { MyEdit } from 'src/components/MyEdit/MyEdit'
import { CompanyReferenceInput } from '../Companies/components/CompanyReferenceInput/CompanyReferenceInput'
import DaysCountField from './components/DaysCountField'
import { EAccountTypesFields } from '../AccountTypes/AccountTypes.type'
import { GTE, LTE } from '../Companies/Comapnies.const'
import { LoginField } from '../../components/Field/LoginField/LoginField'
import Form from '../../components/Form/Form'
import { EMethod } from '../../hooks/useShowApiErrors'
import MyList from '../../components/MyList/MyList'

export const sortByUsers: (field: EUsersFields) => string = (field) =>
  `${EResources.users}.${field}`

const userFilters = [
  <NumberInput
    label='User ID'
    source={EUsersFields.id}
    alwaysOn
    parse={(value) => {
      const int = parseInt(value)
      if (int < 1) return ''
      return isNaN(int) ? '' : int
    }}
  />,
  <TextInput label='First name' source={EUsersFields.first_name} alwaysOn />,
  <TextInput label='Last name' source={EUsersFields.last_name} alwaysOn />,
  <DateInput source={EUsersFields.created + GTE} label={'Created After'} />,
  <DateInput source={EUsersFields.created + LTE} label={'Created Before'} />,
  <DateInput source={EUsersFields.last_login + GTE} label={'Logged After'} />,
  <DateInput source={EUsersFields.last_login + LTE} label={'Logged Before'} />,
  <ReferenceInput
    source={EUsersFields.account_type}
    reference={EResources.accountTypes}
  >
    <AutocompleteInput
      label={'Account Type'}
      source={EAccountTypesFields.name}
      optionText={EAccountTypesFields.name}
      filterToQuery={(searchText) => ({
        [EAccountTypesFields.name]: searchText,
      })}
    />
  </ReferenceInput>,

  <ReferenceInput
    reference={EResources.companies}
    source={EUsersFields.company_id}
  >
    <AutocompleteInput
      filterToQuery={(searchText) => ({
        [ECompaniesFields.short_name]: searchText,
      })}
      label='Short name'
      source={ECompaniesFields.short_name}
      optionText={ECompaniesFields.short_name}
    />
  </ReferenceInput>,

  <ReferenceInput
    reference={EResources.companies}
    source={EUsersFields.company_id}
  >
    <AutocompleteInput
      filterToQuery={(searchText) => ({ [ECompaniesFields.nip]: searchText })}
      label='NIP'
      source={ECompaniesFields.nip}
      optionText={ECompaniesFields.nip}
    />
  </ReferenceInput>,

  <ReferenceInput
    reference={EResources.companies}
    source={EUsersFields.company_id}
  >
    <InputNumber label='Company ID' source={EUsersFields.company_id} />
  </ReferenceInput>,
]

const UsersList = (props) => {
  return (
    <MyList {...props} filters={userFilters}>
      <Datagrid rowClick='show'>
        <NumberField source={EUsersFields.id} />

        <LoginField label={'Zaloguj jako'} source={EUsersFields.email} />

        <ReferenceField
          sortBy={sortByCompany(ECompaniesFields.id)}
          label={'Company ID'}
          {...companyReference}
        >
          <TextField source={ECompaniesFields.id} />
        </ReferenceField>

        <ReferenceField
          sortBy={sortByCompany(ECompaniesFields.short_name)}
          label={'Company short name'}
          {...companyReference}
        >
          <TextField source={ECompaniesFields.short_name} />
        </ReferenceField>

        <ReferenceField
          sortBy={sortByCompany(ECompaniesFields.nip)}
          label={'Company NIP'}
          {...companyReference}
        >
          <TextField source={ECompaniesFields.nip} />
        </ReferenceField>

        <TextField source={EUsersFields.first_name} />
        <TextField source={EUsersFields.last_name} />
        <DateField source={EUsersFields.created} />
        <DateField source={EUsersFields.modified} />
        <DateField source={EUsersFields.last_login} />

        <DaysCountField source={EUsersFields.last_login} />

        <ReferenceField
          sortBy={sortByCompany(ECompaniesFields.visits_counter)}
          label={'Profile visits'}
          {...companyReference}
          link={false}
        >
          <TextField source={ECompaniesFields.visits_counter} />
        </ReferenceField>
        <ReferenceField
          sortBy={sortByCompany(ECompaniesFields.contact_counter)}
          label={'Contact visits'}
          {...companyReference}
          link={false}
        >
          <TextField source={ECompaniesFields.contact_counter} />
        </ReferenceField>
        <ReferenceField
          sortBy={sortByCompany(ECompaniesFields.potential_search_counter)}
          label={'Potential searches'}
          {...companyReference}
          link={false}
        >
          <TextField source={ECompaniesFields.potential_search_counter} />
        </ReferenceField>

        <TextField source={EUsersFields.email} />
        <TextField source={EUsersFields.inquiries_count} />
        <TextField source={EUsersFields.search_queries_count} />

        <EditButton label='Edit' />
      </Datagrid>
    </MyList>
  )
}

const UsersShow = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <NumberField source={EUsersFields.id} />
        <DateField source={EUsersFields.created} />
        <DateField source={EUsersFields.modified} />
        <TextField source={EUsersFields.first_name} />
        <TextField source={EUsersFields.last_name} />
        <TextField source={EUsersFields.phone} />
        <TextField source={EUsersFields.email} />
      </SimpleShowLayout>
    </Show>
  )
}

const UsersEdit = (props) => (
  <MyEdit {...props}>
    <Form method={EMethod.PUT} resource={EResources.users}>
      <InputText source={EUsersFields.first_name} />
      <InputText source={EUsersFields.last_name} />
      <InputText source={EUsersFields.phone} />
      <InputText source={EUsersFields.email} />
      <InputText source={EUsersFields.password} />
      <InputDate source={EUsersFields.modified} />

      <CompanyReferenceInput />
    </Form>
  </MyEdit>
)

export const users: ResourceProps = {
  name: EResources.users,
  list: UsersList,
  show: UsersShow,
  edit: UsersEdit,
}
