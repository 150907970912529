import { useListContext } from 'react-admin'
import { FormProvider, useForm } from 'react-hook-form'
import { Box, Button } from '@mui/material'
import { IMyFilterFormProps } from './MyFilterForm.type'
import { useEffect } from 'react'

const MyFilterForm = ({ filters }: IMyFilterFormProps) => {
  const { displayedFilters, filterValues, setFilters } = useListContext()

  const form = useForm()

  const onSubmit = (values) => {
    if (Object.keys(values).length > 0) {
      setFilters(values, null)
    }
  }

  const resetFilter = () => {
    form.reset()
    setFilters({}, null)
  }

  useEffect(() => {
    if (Object.keys(filterValues).length === 0) return

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { main, ...restValues } = filterValues || {}

    for (const key in restValues) {
      form.setValue(key, filterValues[key])
    }
  }, [filterValues])

  if (!displayedFilters.main) return null

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Box component='span' mr={2} mb={1.5}>
          <Button variant='outlined' color='primary' type='submit'>
            Filtruj
          </Button>
        </Box>
        <Box component='span' mb={1.5}>
          <Button
            variant='outlined'
            color={'error'}
            type={'button'}
            onClick={resetFilter}
          >
            Wyczyść filtry
          </Button>
        </Box>

        <Box
          sx={{
            '& p': {
              display: 'none',
            },
          }}
        >
          <Box
            display='grid'
            gridTemplateColumns={{
              xs: 'repeat(3, 1fr)',
              md: 'repeat(4, 1fr)',
              lg: 'repeat(5, 1fr)',
              xl: 'repeat(6, 1fr)',
            }}
            gap={1}
          >
            {Array.isArray(filters) &&
              filters.map((filter, index) => {
                return (
                  <Box
                    key={index}
                    width={'100%'}
                    display={'flex'}
                    alignItems={'center'}
                    sx={{
                      '& > *': {
                        'flex-grow': '1',
                      },
                    }}
                  >
                    {filter}
                  </Box>
                )
              })}
          </Box>
        </Box>
      </form>
    </FormProvider>
  )
}

export default MyFilterForm
