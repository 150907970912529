import {
  BooleanField,
  Create,
  Datagrid,
  Edit,
  ImageField,
  ImageInput,
  NumberField,
  ResourceProps,
  SelectInput,
  TextField,
} from 'react-admin'
import { EAdsFields } from './Ads.type'
import { InputBoolean, InputText } from 'src/components/Input'
import { EResources } from 'src/types/resource.types'
import { LARGE, LEFT, MEDIUM, RIGHT, SMALL } from './Ads.const'
import ChangeImage from '../Partners/components/ChangeImage'
import AdSizeField from './components/AdSizeField'
import { EMethod } from '../../hooks/useShowApiErrors'
import Form from '../../components/Form/Form'
import MyList from '../../components/MyList/MyList'

const adSizeChoices = [
  { id: 1, name: SMALL },
  { id: 2, name: MEDIUM },
  { id: 3, name: LARGE },
]

const adPositionChoices = [
  { id: LEFT, name: LEFT },
  { id: RIGHT, name: RIGHT },
]

const AdsList = () => (
  <MyList hasCreate={true}>
    <Datagrid rowClick='edit'>
      <ImageField source={EAdsFields.image_url} />
      <TextField source={EAdsFields.id} />
      <TextField source={EAdsFields.name} />
      <BooleanField source={EAdsFields.active} />
      <TextField source={EAdsFields.file_dir} />
      <AdSizeField source={EAdsFields.ad_size} />
      <NumberField source={EAdsFields.company_id} />
      <TextField source={EAdsFields.position} />
      <TextField source={EAdsFields.url} />
    </Datagrid>
  </MyList>
)

const AdsEdit = () => {
  return (
    <Edit>
      <Form method={EMethod.PUT} resource={EResources.ads}>
        <ChangeImage />
        <InputText source={EAdsFields.name} />
        <InputBoolean source={EAdsFields.active} />
        <InputText source={EAdsFields.file_dir} />
        <SelectInput source={EAdsFields.ad_size} choices={adSizeChoices} />
        <InputText source={EAdsFields.company_id} />
        <SelectInput source={EAdsFields.position} choices={adPositionChoices} />
        <InputText source={EAdsFields.url} />
      </Form>
    </Edit>
  )
}

const AdsCreate = () => (
  <Create>
    <Form method={EMethod.POST} resource={EResources.ads}>
      <InputText source={EAdsFields.name} />
      <InputBoolean source={EAdsFields.active} />
      <ImageInput source={EAdsFields.file}>
        <ImageField source={'src'} title='title' />
      </ImageInput>
      <SelectInput source={EAdsFields.ad_size} choices={adSizeChoices} />
      <NumberField source={EAdsFields.company_id} />
      <SelectInput source={EAdsFields.position} choices={adPositionChoices} />
      <InputText source={EAdsFields.url} />
    </Form>
  </Create>
)

export const ads: ResourceProps = {
  name: EResources.ads,
  list: AdsList,
  edit: AdsEdit,
  create: AdsCreate,
}
