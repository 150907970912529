import {
  BooleanField,
  Datagrid,
  Edit,
  NumberField,
  ResourceProps,
  SelectInput,
  TextField,
} from 'react-admin'
import { EGrupakatsFields } from './Grupakats.type'
import {
  InputBoolean,
  InputNumber,
  InputText,
  InputWysiwyg,
} from 'src/components/Input'
import { TruncatedTextField } from 'src/components/Field'
import { EResources } from 'src/types/resource.types'
import CategoryField from './components/CategoryField'
import { GOOD, SERVICE } from './components/CategoryField.const'
import Form from '../../components/Form/Form'
import { EMethod } from '../../hooks/useShowApiErrors'
import SelectFilter from '../Groups/components/SelectFilter/SelectFilter'
import MyList from '../../components/MyList/MyList'
import { MIN_TRUNCATE_LIMIT } from '../../components/Field/TruncatedTextField/TruncatedTextField.const'

const grupakatsFilters = [
  <SelectFilter
    source={EGrupakatsFields.grupa_kat_id_nad}
    url={'grupakats?filter={"grupa_kat_id_nad": null}'}
    alwaysOn
  />,
]

const GrupakatsList = () => (
  <MyList filters={grupakatsFilters}>
    <Datagrid rowClick='edit'>
      <NumberField source={EGrupakatsFields.id} />
      <NumberField source={EGrupakatsFields.grupa_kat_id} />
      <BooleanField source={EGrupakatsFields.czy_oferta} />
      <TruncatedTextField
        source={EGrupakatsFields.description}
        limit={MIN_TRUNCATE_LIMIT}
      />
      <NumberField source={EGrupakatsFields.grupa_id} />
      <NumberField source={EGrupakatsFields.grupa_kat_id_nad} />
      <TextField source={EGrupakatsFields.name_alt} />
      <TextField source={EGrupakatsFields.nazwa_in} />
      <TextField source={EGrupakatsFields.nazwa_out} />
      <TextField source={EGrupakatsFields.nazwa_st} />
      <TruncatedTextField
        source={EGrupakatsFields.opis_in}
        limit={MIN_TRUNCATE_LIMIT}
      />
      <TruncatedTextField
        source={EGrupakatsFields.opis_out}
        limit={MIN_TRUNCATE_LIMIT}
      />
      <TruncatedTextField
        source={EGrupakatsFields.opis_st}
        limit={MIN_TRUNCATE_LIMIT}
      />
      <TruncatedTextField
        source={EGrupakatsFields.seo_text_meta}
        limit={MIN_TRUNCATE_LIMIT}
      />
      <TruncatedTextField
        source={EGrupakatsFields.seo_title}
        limit={MIN_TRUNCATE_LIMIT}
      />
      <TextField source={EGrupakatsFields.slug} />
      <NumberField source={EGrupakatsFields.sort} />
      <CategoryField source={EGrupakatsFields.default_pos_type} />
      <TruncatedTextField
        source={EGrupakatsFields.uwagi}
        limit={MIN_TRUNCATE_LIMIT}
      />
    </Datagrid>
  </MyList>
)

const GrupakatsEdit = () => (
  <Edit>
    <Form method={EMethod.PUT} resource={EResources.grupakats}>
      <InputNumber source={EGrupakatsFields.id} />
      <InputNumber source={EGrupakatsFields.grupa_kat_id} />
      <InputBoolean source={EGrupakatsFields.czy_oferta} />
      <InputWysiwyg source={EGrupakatsFields.description} />
      <InputNumber source={EGrupakatsFields.grupa_id} />
      <InputNumber source={EGrupakatsFields.grupa_kat_id_nad} />
      <InputText source={EGrupakatsFields.name_alt} />
      <InputText source={EGrupakatsFields.nazwa_in} />
      <InputText source={EGrupakatsFields.nazwa_out} />
      <InputText source={EGrupakatsFields.nazwa_st} />
      <InputText source={EGrupakatsFields.opis_in} />
      <InputText source={EGrupakatsFields.opis_out} />
      <InputText source={EGrupakatsFields.opis_st} />
      <InputText source={EGrupakatsFields.seo_text_meta} />
      <InputText source={EGrupakatsFields.seo_title} />
      <InputText source={EGrupakatsFields.slug} />
      <InputNumber source={EGrupakatsFields.sort} />
      <SelectInput
        source={EGrupakatsFields.default_pos_type}
        choices={[
          { id: 0, name: GOOD },
          { id: 1, name: SERVICE },
        ]}
      />
      <InputText source={EGrupakatsFields.uwagi} />
    </Form>
  </Edit>
)

export const grupakats: ResourceProps = {
  name: EResources.grupakats,
  list: GrupakatsList,
  edit: GrupakatsEdit,
}
