export enum EUsersFields {
  id = 'id',
  status_id = 'status_id',
  created = 'created',
  modified = 'modified',
  last_login = 'last_login',
  first_name = 'first_name',
  last_name = 'last_name',
  phone = 'phone',
  email = 'email',
  role_id = 'role_id',
  account_type = 'account_type',
  hash = 'hash',
  hash_mail = 'hash_mail',
  first_time_login = 'first_time_login',
  agreement = 'agreement',
  status_sending_email_with_regulations = 'status_sending_email_with_regulations',
  invoice_agreement = 'invoice_agreement',
  show_in_search = 'show_in_search',
  is_enabled_seo = 'is_enabled_seo',
  company_id = 'company_id',
  password = 'password',
  inquiries_count = 'inquiries_count',
  search_queries_count = 'search_queries_count',
}
