import {
  Datagrid,
  ResourceProps,
  TextField,
  Edit,
  ReferenceInput,
  SelectInput,
  Create,
  ReferenceField,
} from 'react-admin'
import { EResources } from '../../types/resource.types'
import MyList from '../../components/MyList/MyList'
import { EAccountTypePermissionFields } from './AccountTypePermissions.type'
import { EMethod } from '../../hooks/useShowApiErrors'
import Form from '../../components/Form/Form'
import { EAccountTypesFields } from '../AccountTypes/AccountTypes.type'
import DynamicInput from './components/DynamicInput/DynamicInput'
import PermissionSelect from './components/PermissionSelect/PermissionSelect'
import { EPermissionsFields } from '../Permissions/Permissions.type.'

const AccountTypePermissionList = () => (
  <MyList hasCreate={true}>
    <Datagrid rowClick={'edit'}>
      <TextField source={EAccountTypePermissionFields.id} />
      <ReferenceField
        source={EAccountTypePermissionFields.account_type_id}
        reference={EResources.accountTypes}
      >
        <TextField source={EAccountTypesFields.name} />
      </ReferenceField>
      <TextField
        label={'Account type id'}
        source={EAccountTypePermissionFields.account_type_id}
      />

      <TextField
        sortBy={EResources.permissions + '.name'}
        source={EAccountTypePermissionFields.permission_name}
      />
      <TextField source={EAccountTypePermissionFields.value} />

      <TextField
        source={EAccountTypePermissionFields.permission_type}
        sortBy={EResources.permissions + '.type'}
      />

      <TextField
        label={'Permission id'}
        source={EAccountTypePermissionFields.permission_id}
      />
    </Datagrid>
  </MyList>
)

const AccountTypePermissionEdit = () => (
  <Edit>
    <Form method={EMethod.PUT} resource={EResources.accountTypePermissions}>
      <ReferenceInput
        source={EAccountTypePermissionFields.account_type_id}
        reference={EResources.accountTypes}
      >
        <SelectInput
          source={EAccountTypesFields.id}
          optionText={EAccountTypesFields.name}
        />
      </ReferenceInput>

      <ReferenceInput
        source={EAccountTypePermissionFields.permission_id}
        reference={EResources.permissions}
      >
        <SelectInput
          source={EPermissionsFields.id}
          optionText={EPermissionsFields.name}
        />
      </ReferenceInput>

      <DynamicInput />
    </Form>
  </Edit>
)

const AccountTypePermissionCreate = () => {
  return (
    <Create>
      <Form method={EMethod.POST} resource={EResources.accountTypePermissions}>
        <ReferenceInput
          source={EAccountTypePermissionFields.account_type_id}
          reference={EResources.accountTypes}
        >
          <SelectInput
            source={EAccountTypesFields.id}
            optionText={EAccountTypesFields.name}
          />
        </ReferenceInput>

        <PermissionSelect />
      </Form>
    </Create>
  )
}
export const accountTypePermissions: ResourceProps = {
  name: EResources.accountTypePermissions,
  list: AccountTypePermissionList,
  edit: AccountTypePermissionEdit,
  create: AccountTypePermissionCreate,
}
