export enum EOffersFields {
  id = 'id',
  category = 'category',
  user_id = 'user_id',
  company_id = 'company_id',
  name = 'name',
  created = 'created',
  modified = 'modified',
  custom_contact = 'custom_contact',
  data_akt = 'data_akt',
  data_anul = 'data_anul',
  data_end = 'data_end',
  data_start = 'data_start',
  datal_blok = 'datal_blok',
  description = 'description',
  email = 'email',
  offerstatus_id = 'offerstatus_id',
  private_note = 'private_note',
  seo_text_html = 'seo_text_html',
  seo_text_meta = 'seo_text_meta',
  seo_title = 'seo_title',
  slug_url = 'slug_url',
  slug_url_old = 'slug_url_old',
  tel = 'tel',
}
