import { AutocompleteInput } from 'react-admin'
import { EGroupsFields } from '../../Groups.type'
import { useEffect, useState } from 'react'
import { useLocalStorage } from '../../../../hooks/useLocalStorage'
import { TSelectChoice } from './SelectFilter.type'

interface ISelectFilterProps {
  url: string
  alwaysOn: boolean
  source: string
}

const SelectFilter = ({ url, alwaysOn, source }: ISelectFilterProps) => {
  const [choices, setChoices] = useState<TSelectChoice[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const { value: token } = useLocalStorage('auth')

  useEffect(() => {
    const getChoices = async () => {
      try {
        setIsLoading(true)
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/${url}`,
          {
            headers: {
              authorization: token,
            },
          }
        )

        const filterChoices = (await response
          .json()
          .then((values: (typeof EGroupsFields)[]) =>
            values.map(
              (
                value
              ): { name: EGroupsFields.nazwa_out; id: EGroupsFields.id } => {
                return { id: value.id, name: value.nazwa_out }
              }
            )
          )) as TSelectChoice[]

        setChoices(filterChoices)
      } finally {
        setIsLoading(false)
      }
    }

    getChoices()
  }, [])

  return (
    <AutocompleteInput
      sx={{ width: '300px' }}
      disabled={isLoading}
      isLoading={isLoading}
      source={source}
      choices={choices}
      alwaysOn={alwaysOn}
    />
  )
}

export default SelectFilter
