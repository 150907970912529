import { Create, Datagrid, Edit, ResourceProps, TextField } from 'react-admin'
import { EReferencesFields } from './References.type'
import { InputText } from 'src/components/Input'
import { EResources } from 'src/types/resource.types'
import Form from '../../components/Form/Form'
import { EMethod } from '../../hooks/useShowApiErrors'
import MyList from '../../components/MyList/MyList'

const ReferencesList = () => (
  <MyList hasCreate={true}>
    <Datagrid rowClick='edit'>
      <TextField source={EReferencesFields.id} />
      <TextField source={EReferencesFields.name} />
      <TextField source={EReferencesFields.field_type} />
    </Datagrid>
  </MyList>
)

const ReferencesEdit = () => (
  <Edit>
    <Form method={EMethod.PUT} resource={EResources.references}>
      <InputText source={EReferencesFields.id} />
      <InputText source={EReferencesFields.name} />
      <InputText source={EReferencesFields.field_type} />
    </Form>
  </Edit>
)

const ReferencesCreate = () => (
  <Create>
    <Form method={EMethod.POST} resource={EResources.references}>
      <InputText source={EReferencesFields.name} />
      <InputText source={EReferencesFields.field_type} />
    </Form>
  </Create>
)

export const references: ResourceProps = {
  name: EResources.references,
  list: ReferencesList,
  edit: ReferencesEdit,
  create: ReferencesCreate,
}
