import {
  RichTextInputToolbar,
  LevelSelect,
  FormatButtons,
  AlignmentButtons,
  ListButtons,
  LinkButtons,
  QuoteButtons,
  ClearButtons,
  useTiptapEditor,
  ImageButtons,
} from 'ra-input-rich-text'
import Remove from '@mui/icons-material/Remove'
import { ToggleButton } from '@mui/material'

const WysiwygToolbar = () => {
  const size = 'large'

  const editor = useTiptapEditor()

  return (
    <RichTextInputToolbar>
      <LevelSelect size={size} />
      <FormatButtons size={size} />
      <AlignmentButtons size={size} />
      <ListButtons size={size} />
      <LinkButtons size={size} />
      <QuoteButtons size={size} />
      <ClearButtons size={size} />
      <ImageButtons size={size} />
      <ToggleButton
        size={size}
        aria-label='Add an horizontal rule'
        title='Add an horizontal rule'
        value='left'
        onClick={() => editor.chain().focus().setHorizontalRule().run()}
        selected={editor && editor.isActive('horizontalRule')}
      >
        <Remove fontSize='inherit' />
      </ToggleButton>
    </RichTextInputToolbar>
  )
}

export default WysiwygToolbar
