export enum EAdsFields {
  id = 'id',
  name = 'name',
  active = 'active',
  file = 'file',
  file_dir = 'file_dir',
  ad_size = 'ad_size',
  company_id = 'company_id',
  position = 'position',
  url = 'url',
  image_url = 'image_url',
}
