import { useRecordContext } from 'react-admin'
import { GOOD, SERVICE } from './CategoryField.const'

const CategoryField = ({ source }: { source: string }) => {
  const record = useRecordContext()

  const getCategory = () => {
    const categoryNumber = record[source]

    if (categoryNumber) return SERVICE
    if (categoryNumber === 0 || categoryNumber === false) return GOOD
    return ''
  }

  if (!record) return null
  return <p>{getCategory()}</p>
}

export default CategoryField
