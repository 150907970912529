type TFetchFile = ({
  fileUrl,
}: {
  fileUrl: string | undefined | null
}) => Promise<File | undefined>

export const fetchFile: TFetchFile = async ({ fileUrl }) => {
  if (!fileUrl) return undefined
  const name = fileUrl.split('/').at(-1)

  try {
    const response = await fetch(fileUrl, {
      method: 'GET',
    })

    const blob = await response.blob()

    return new File([blob], name || 'uploaded-file', {
      lastModified: new Date().getTime(),
      type: blob.type,
    })
  } catch (err) {
    return undefined
  }
}
