import { useRecordContext } from 'react-admin'
import { COMPANY, INDIVIDUAL } from '../../Comapnies.const'

const LegalStatusField = ({ source }: { source: string }) => {
  const record = useRecordContext()

  const getLegalStatus = () => {
    const legalStatusNumber = record[source]

    if (legalStatusNumber === 0) return INDIVIDUAL
    if (legalStatusNumber === 1) return COMPANY
    return legalStatusNumber
  }

  if (!record) return null
  return <p>{getLegalStatus()}</p>
}

export default LegalStatusField
