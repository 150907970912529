import {
  Create,
  Datagrid,
  Edit,
  ResourceProps,
  TextField,
  TextInput,
} from 'react-admin'
import { EResources } from '../../types/resource.types'
import { ERedirectsFields } from './Redirects.type'
import { InputText } from '../../components/Input'
import Form from '../../components/Form/Form'
import { EMethod } from '../../hooks/useShowApiErrors'
import MyList from '../../components/MyList/MyList'

const redirectFilters = [
  <TextInput label='from url' source={ERedirectsFields.from_url} alwaysOn />,
  <TextInput label='to url' source={ERedirectsFields.to_url} alwaysOn />,
]

const RedirectsList = (props) => {
  return (
    <MyList {...props} filters={redirectFilters} hasCreate={true}>
      <Datagrid rowClick='edit'>
        <TextField source={ERedirectsFields.id} />
        <TextField source={ERedirectsFields.from_url} />
        <TextField source={ERedirectsFields.to_url} />
      </Datagrid>
    </MyList>
  )
}

const RedirectsCreate = () => {
  return (
    <Create>
      <Form method={EMethod.POST} resource={EResources.redirects}>
        <InputText source={ERedirectsFields.from_url} />
        <InputText source={ERedirectsFields.to_url} />
      </Form>
    </Create>
  )
}

const RedirectsEdit = () => {
  return (
    <Edit>
      <Form method={EMethod.PUT} resource={EResources.redirects}>
        <InputText source={ERedirectsFields.from_url} />
        <InputText source={ERedirectsFields.to_url} />
      </Form>
    </Edit>
  )
}

export const redirects: ResourceProps = {
  name: EResources.redirects,
  list: RedirectsList,
  create: RedirectsCreate,
  edit: RedirectsEdit,
}
