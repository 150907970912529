import { BooleanInput } from 'react-admin'

interface IInputBooleanProps {
  source: string
}

const InputBoolean = ({ source }: IInputBooleanProps) => {
  return <BooleanInput name={source} source={source} />
}

export { InputBoolean }
