import { NumberInput } from 'react-admin'

interface IInputNumberProps {
  source: string
  label?: string
}

const InputNumber = ({ source, label }: IInputNumberProps) => {
  return <NumberInput fullWidth name={source} source={source} label={label} />
}

export { InputNumber }
