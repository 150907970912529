import { useLocalStorage } from 'src/hooks/useLocalStorage'
import { EMethod } from 'src/hooks/useShowApiErrors'

const API_URL = process.env.REACT_APP_API_URL

const useSimpleFetch = () => {
  const { value: token } = useLocalStorage('auth')

  const options = {
    method: EMethod.POST,
    headers: {
      authorization: token,
    },
  }

  const simpleFetch = async (url: string) => {
    return await fetch(API_URL + url, options)
  }

  return {
    simpleFetch,
  }
}

export { useSimpleFetch }
