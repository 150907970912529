export enum EInquiriesFields {
  id = 'id',
  additional_id = 'additional_id',
  topic = 'topic',
  slug = 'slug',
  user_id = 'user_id',
  company_id = 'company_id',
  description = 'description',
  type = 'type',
  quantity = 'quantity',
  different_details = 'different_details',
  acceptable_waiting_time = 'acceptable_waiting_time',
  suggested_price = 'suggested_price',
  visible_only_for_cluster_members = 'visible_only_for_cluster_members',
  system_inquiry = 'system_inquiry',
  status = 'status',
  seo_title = 'seo_title',
  seo_text_meta = 'seo_text_meta',
  expires_at = 'expires_at',
  deadline = 'deadline',
  modified_at = 'modified_at',
  created_at = 'created_at',
  deleted_at = 'deleted_at',
  visits_counter = 'visits_counter',
}
