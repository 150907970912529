import { SelectInput, TextInput } from 'react-admin'
import { EAccountTypePermissionFields } from '../../AccountTypePermissions.type'
import { EPermissionsFields } from '../../../Permissions/Permissions.type.'
import { useEffect, useState } from 'react'
import { useLocalStorage } from '../../../../hooks/useLocalStorage'
import { PERMISSIONS_URL } from './PermissionSelect.const'
import { TPermissionChoice } from './PermissionSelect.type'

const PermissionSelect = () => {
  const [permissions, setPermissions] = useState<TPermissionChoice[]>([])
  const [selectedPermissionId, setSelectedPermissionId] = useState<number>()
  const [isLoading, setIsLoading] = useState(false)

  const { value: token } = useLocalStorage('auth')

  const getPermissionType = () => {
    if (permissions.length === 0 || !selectedPermissionId) return null

    return permissions.find(({ id }) => id === selectedPermissionId).type
  }

  useEffect(() => {
    const getPermissions = async () => {
      try {
        setIsLoading(true)
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/${PERMISSIONS_URL}`,
          {
            headers: {
              authorization: token,
            },
          }
        )

        const filterChoices = (await response
          .json()
          .then((values: { [key in EPermissionsFields] }[]) =>
            values.map((value) => {
              return { id: value.id, name: value.name, type: value.type }
            })
          )) as TPermissionChoice[]

        setPermissions(filterChoices)
      } finally {
        setIsLoading(false)
      }
    }

    getPermissions()
  }, [])

  return (
    <>
      <SelectInput
        choices={permissions}
        isLoading={isLoading}
        source={EAccountTypePermissionFields.permission_id}
        optionText={`${EPermissionsFields.name}`}
        onChange={(e) => setSelectedPermissionId(e.target.value)}
      />

      {getPermissionType() === 'INTEGER' ? (
        <TextInput
          source={EAccountTypePermissionFields.value}
          defaultValue={''}
        />
      ) : null}
    </>
  )
}

export default PermissionSelect
