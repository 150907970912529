import { useRecordContext } from 'react-admin'
import { LARGE, MEDIUM, SMALL } from '../Ads.const'

const AdSizeField = ({ source }: { source: string }) => {
  const record = useRecordContext()

  const getAdSize = () => {
    const adSizeNumber = record[source]

    if (adSizeNumber === 1) return SMALL
    if (adSizeNumber === 2) return MEDIUM
    if (adSizeNumber === 3) return LARGE
  }

  if (!record) return null
  return <p>{getAdSize()}</p>
}

export default AdSizeField
