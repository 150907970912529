import {
  BooleanField,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  ResourceProps,
  SelectInput,
  Show,
  SimpleShowLayout,
  TextField,
  NullableBooleanInput,
  useNotify,
} from 'react-admin'
import { useParams } from 'react-router-dom'
import { Button, Stack } from '@mui/material'
import { ECompaniesFields } from './Companies.type'
import { InputBoolean, InputNumber, InputText } from 'src/components/Input'
import { TruncatedTextField } from 'src/components/Field'
import { EResources } from 'src/types/resource.types'
import { TItemReference } from 'src/types/reference.type'
import LegalStatusField from './components/LegalStatusField/LegalStatusField'
import { COMPANY, GTE, INDIVIDUAL, LTE, USERS_IDS } from './Comapnies.const'
import { EAccountTypesFields } from '../AccountTypes/AccountTypes.type'
import { EVoivodeshipsFields } from '../Voivodeships/Voivodeship.type'
import { EMethod } from '../../hooks/useShowApiErrors'
import Form from '../../components/Form/Form'
import MyList from '../../components/MyList/MyList'
import { useSimpleFetch } from 'src/hooks/useSimpleFetch'

export const companyReference: TItemReference = {
  link: 'show',
  reference: EResources.companies,
  source: ECompaniesFields.reference,
}

export const sortByCompany: (field: ECompaniesFields) => string = (field) =>
  `${EResources.companies}.${field}`

const companyFilters = [
  <NullableBooleanInput
    source={ECompaniesFields.is_limited}
    alwaysOn
    falseLabel='No'
    trueLabel='Yes'
  />,

  <NullableBooleanInput
    source={ECompaniesFields.seo_section_visible}
    alwaysOn
    falseLabel='No'
    trueLabel='Yes'
  />,

  <NullableBooleanInput
    source={ECompaniesFields.is_cluster_mt}
    alwaysOn
    falseLabel='No'
    trueLabel='Yes'
  />,

  <NullableBooleanInput
    source={ECompaniesFields.show_in_search}
    alwaysOn
    falseLabel='No'
    trueLabel='Yes'
  />,

  <NumberInput source={ECompaniesFields.max_promoted} />,
  <NumberInput source={ECompaniesFields.max_recommended} />,
  <DateInput
    source={ECompaniesFields.created_at + GTE}
    label={'Created After'}
  />,
  <DateInput
    source={ECompaniesFields.created_at + LTE}
    label={'Created Before'}
  />,
  <ReferenceInput
    source={ECompaniesFields.voivodeship_id}
    reference={EResources.voivodeships}
  >
    <SelectInput
      label='Voivodeship'
      source={EVoivodeshipsFields.id}
      optionText={EVoivodeshipsFields.name}
    />
  </ReferenceInput>,
  <ReferenceInput
    source={ECompaniesFields.account_type_id}
    reference={EResources.accountTypes}
  >
    <SelectInput
      source={EAccountTypesFields.id}
      optionText={EAccountTypesFields.name}
    />
  </ReferenceInput>,
  <InputText source={ECompaniesFields.post_seat} />,
  <InputNumber source={ECompaniesFields.id} />,
  <InputNumber source={ECompaniesFields.nip} />,
  <InputNumber source={ECompaniesFields.users_ids} label={'User ID'} />,
  <InputText source={ECompaniesFields.short_name} />,
  <SelectInput
    source={ECompaniesFields.legal_status}
    choices={[
      { id: 0, name: INDIVIDUAL },
      { id: 1, name: COMPANY },
    ]}
  />,
]

const CompaniesList = (props) => (
  <MyList {...props} filters={companyFilters}>
    <Datagrid rowClick='show'>
      <NumberField source={ECompaniesFields.id} />
      <NumberField source={ECompaniesFields.status} />
      <NumberField source={ECompaniesFields.central} />
      <LegalStatusField source={ECompaniesFields.legal_status} />
      <TextField source={ECompaniesFields.name} />
      <ReferenceField
        source={ECompaniesFields.account_type_id}
        reference={EResources.accountTypes}
      >
        <TextField source={EAccountTypesFields.name} />
      </ReferenceField>
      <TextField source={ECompaniesFields.users_ids} label={USERS_IDS} />
      <TextField source={ECompaniesFields.short_name} />
      <NumberField source={ECompaniesFields.year} />
      <TruncatedTextField source={ECompaniesFields.description} />
      <TextField source={ECompaniesFields.city} />
      <TextField source={ECompaniesFields.street} />
      <TextField source={ECompaniesFields.street_number} />
      <TextField source={ECompaniesFields.locale_number} />
      <DateField source={ECompaniesFields.created_at} />
      <TextField source={ECompaniesFields.post_code} />
      <TextField source={ECompaniesFields.post_seat} />
      <TextField source={ECompaniesFields.district} />
      <ReferenceField
        reference={EResources.voivodeships}
        source={ECompaniesFields.voivodeship_id}
      >
        <TextField source={EVoivodeshipsFields.name} />
      </ReferenceField>
      <TextField source={ECompaniesFields.nip} />
      <TextField source={ECompaniesFields.phone} />
      <TextField source={ECompaniesFields.email} />
      <TextField source={ECompaniesFields.branches} />
      <TextField source={ECompaniesFields.mail_contacts_count} />
      <DateField source={ECompaniesFields.account_type_expires_at} />
      <TextField source={ECompaniesFields.available_positions_count} />
      <NumberField source={ECompaniesFields.is_public_email} />
      <TextField source={ECompaniesFields.www} />
      <NumberField source={ECompaniesFields.company_id} />
      <NumberField source={ECompaniesFields.trade_id} />
      <NumberField source={ECompaniesFields.reference_id} />
      <TextField source={ECompaniesFields.reference_custom_value} />
      <NumberField source={ECompaniesFields.latitude} />
      <NumberField source={ECompaniesFields.longitude} />
      <NumberField source={ECompaniesFields.visits_counter} />
      <NumberField source={ECompaniesFields.potential_search_counter} />
      <NumberField source={ECompaniesFields.contact_counter} />
      <TextField source={ECompaniesFields.logo} />
      <TextField source={ECompaniesFields.logo_dir} />
      <NumberField source={ECompaniesFields.max_recommended} />
      <NumberField source={ECompaniesFields.max_promoted} />
      <TextField source={ECompaniesFields.factory_post_code} />
      <TextField source={ECompaniesFields.factory_post_seat} />
      <TextField source={ECompaniesFields.factory_street} />
      <TextField source={ECompaniesFields.factory_street_number} />
      <TextField source={ECompaniesFields.factory_locale_number} />
      <TextField source={ECompaniesFields.factory_voivodeship} />
      <TextField source={ECompaniesFields.factory_phone} />
      <TextField source={ECompaniesFields.factory_email} />
      <TextField source={ECompaniesFields.factory_www} />
      <BooleanField source={ECompaniesFields.is_factory_address} />
      <TextField source={ECompaniesFields.slug_url} />
      <TextField source={ECompaniesFields.slug_url_old} />
      <TextField source={ECompaniesFields.seo_text_meta} />
      <TruncatedTextField source={ECompaniesFields.seo_text_html} />
      <TextField source={ECompaniesFields.seo_title} />
      <BooleanField source={ECompaniesFields.plus_group} />
      <BooleanField source={ECompaniesFields.seo_section_visible} />
      <BooleanField source={ECompaniesFields.seo_section_for_edit} />
      <BooleanField source={ECompaniesFields.is_complete} />
      <BooleanField source={ECompaniesFields.show_similar} />
      <BooleanField source={ECompaniesFields.is_similar_opened} />
      <BooleanField source={ECompaniesFields.is_archival} />
      <BooleanField looseValue source={ECompaniesFields.is_r_account} />
      <BooleanField source={ECompaniesFields.is_internet_company_card} />
      <BooleanField looseValue source={ECompaniesFields.is_trial} />
      <BooleanField looseValue source={ECompaniesFields.is_cluster_mt} />
      <BooleanField looseValue source={ECompaniesFields.is_enabled_seo} />
      <BooleanField
        source={ECompaniesFields.show_in_search}
        looseValue={true}
      />
      <BooleanField source={ECompaniesFields.is_limited} looseValue={true} />
      <EditButton label='Edit' />
    </Datagrid>
  </MyList>
)

const CompaniesShow = (props) => {
  const { id } = useParams()
  const notify = useNotify()
  const { simpleFetch } = useSimpleFetch()

  const updateMetaAndTitle = async () => {
    const result = await simpleFetch(`/companies/${id}/generateMetaTitle`)

    if (!result.ok) {
      notify('Cos poszlo nie tak', { type: 'error' })
      return
    }

    notify('Zaktualizowalo pomyslnie', { type: 'success' })
  }

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <Stack direction={'row'}>
          <Button
            onClick={updateMetaAndTitle}
            variant='contained'
            color={'error'}
            type={'button'}
            sx={{ ml: 'auto', mr: 2, lineHeight: 'normal' }}
          >
            Aktualizuj meta i title
          </Button>
        </Stack>

        <NumberField source={ECompaniesFields.id} />
        <NumberField source={ECompaniesFields.status} />
        <NumberField source={ECompaniesFields.central} />
        <LegalStatusField source={ECompaniesFields.legal_status} />
        <TextField source={ECompaniesFields.name} />
        <ReferenceField
          source={ECompaniesFields.account_type_id}
          reference={EResources.accountTypes}
        >
          <TextField source={EAccountTypesFields.name} />
        </ReferenceField>
        <TextField source={ECompaniesFields.users_ids} label={USERS_IDS} />
        <TextField source={ECompaniesFields.short_name} />
        <NumberField source={ECompaniesFields.year} />
        <TruncatedTextField source={ECompaniesFields.description} />
        <TextField source={ECompaniesFields.city} />
        <TextField source={ECompaniesFields.street} />
        <TextField source={ECompaniesFields.street_number} />
        <TextField source={ECompaniesFields.locale_number} />
        <DateField source={ECompaniesFields.created_at} />
        <TextField source={ECompaniesFields.post_code} />
        <TextField source={ECompaniesFields.post_seat} />
        <TextField source={ECompaniesFields.district} />
        <ReferenceField
          reference={EResources.voivodeships}
          source={ECompaniesFields.voivodeship_id}
        >
          <TextField source={EVoivodeshipsFields.name} />
        </ReferenceField>
        <TextField source={ECompaniesFields.nip} />
        <TextField source={ECompaniesFields.phone} />
        <TextField source={ECompaniesFields.email} />
        <TextField source={ECompaniesFields.branches} />
        <TextField source={ECompaniesFields.mail_contacts_count} />
        <DateField source={ECompaniesFields.account_type_expires_at} />
        <TextField source={ECompaniesFields.available_positions_count} />
        <NumberField source={ECompaniesFields.is_public_email} />
        <TextField source={ECompaniesFields.www} />
        <NumberField source={ECompaniesFields.company_id} />
        <NumberField source={ECompaniesFields.trade_id} />
        <NumberField source={ECompaniesFields.reference_id} />
        <TextField source={ECompaniesFields.reference_custom_value} />
        <NumberField source={ECompaniesFields.latitude} />
        <NumberField source={ECompaniesFields.longitude} />
        <NumberField source={ECompaniesFields.visits_counter} />
        <NumberField source={ECompaniesFields.potential_search_counter} />
        <NumberField source={ECompaniesFields.contact_counter} />
        <TextField source={ECompaniesFields.logo} />
        <TextField source={ECompaniesFields.logo_dir} />
        <NumberField source={ECompaniesFields.max_recommended} />
        <NumberField source={ECompaniesFields.max_promoted} />
        <TextField source={ECompaniesFields.factory_post_code} />
        <TextField source={ECompaniesFields.factory_post_seat} />
        <TextField source={ECompaniesFields.factory_street} />
        <TextField source={ECompaniesFields.factory_street_number} />
        <TextField source={ECompaniesFields.factory_locale_number} />
        <TextField source={ECompaniesFields.factory_voivodeship} />
        <TextField source={ECompaniesFields.factory_phone} />
        <TextField source={ECompaniesFields.factory_email} />
        <TextField source={ECompaniesFields.factory_www} />
        <BooleanField source={ECompaniesFields.is_factory_address} />
        <TextField source={ECompaniesFields.slug_url} />
        <TextField source={ECompaniesFields.slug_url_old} />
        <TextField source={ECompaniesFields.seo_text_meta} />
        <TruncatedTextField source={ECompaniesFields.seo_text_html} />
        <TextField source={ECompaniesFields.seo_title} />
        <BooleanField source={ECompaniesFields.plus_group} />
        <BooleanField source={ECompaniesFields.seo_section_visible} />
        <BooleanField source={ECompaniesFields.seo_section_for_edit} />
        <BooleanField source={ECompaniesFields.is_complete} />
        <BooleanField source={ECompaniesFields.show_similar} />
        <BooleanField source={ECompaniesFields.is_similar_opened} />
        <BooleanField source={ECompaniesFields.is_archival} />
        <BooleanField looseValue source={ECompaniesFields.is_r_account} />
        <BooleanField source={ECompaniesFields.is_internet_company_card} />
        <BooleanField looseValue source={ECompaniesFields.is_trial} />
        <BooleanField looseValue source={ECompaniesFields.is_cluster_mt} />
        <BooleanField looseValue source={ECompaniesFields.is_enabled_seo} />
        <BooleanField
          source={ECompaniesFields.show_in_search}
          looseValue={true}
        />
        <BooleanField source={ECompaniesFields.is_limited} looseValue={true} />
      </SimpleShowLayout>
    </Show>
  )
}

const CompaniesEdit = (props) => {
  return (
    <Edit {...props}>
      <Form method={EMethod.PUT} resource={EResources.companies}>
        <InputNumber source={ECompaniesFields.id} />
        <InputNumber source={ECompaniesFields.status} />
        <InputNumber source={ECompaniesFields.central} />
        <SelectInput
          source={ECompaniesFields.legal_status}
          choices={[
            { id: 0, name: INDIVIDUAL },
            { id: 1, name: COMPANY },
          ]}
        />
        <InputText source={ECompaniesFields.name} />
        <ReferenceInput
          source={ECompaniesFields.account_type_id}
          reference={EResources.accountTypes}
        >
          <SelectInput
            source={EAccountTypesFields.id}
            optionText={EAccountTypesFields.name}
          />
        </ReferenceInput>
        <InputText source={ECompaniesFields.short_name} />
        <InputNumber source={ECompaniesFields.year} />
        <InputText source={ECompaniesFields.description} />
        <InputText source={ECompaniesFields.city} />
        <InputText source={ECompaniesFields.street} />
        <InputText source={ECompaniesFields.street_number} />
        <InputText source={ECompaniesFields.locale_number} />
        <InputText source={ECompaniesFields.post_code} />
        <InputText source={ECompaniesFields.post_seat} />
        <InputText source={ECompaniesFields.district} />
        <InputText source={ECompaniesFields.voivodeship_id} />
        <ReferenceInput
          source={ECompaniesFields.voivodeship_id}
          reference={EResources.voivodeships}
        >
          <SelectInput
            source={EVoivodeshipsFields.id}
            optionText={EVoivodeshipsFields.name}
          />
        </ReferenceInput>
        <InputText source={ECompaniesFields.nip} />
        <InputText source={ECompaniesFields.phone} />
        <InputText source={ECompaniesFields.email} />
        <InputText source={ECompaniesFields.available_positions_count} />
        <InputNumber source={ECompaniesFields.mail_contacts_count} />
        <DateInput source={ECompaniesFields.account_type_expires_at} />
        <InputText source={ECompaniesFields.www} />
        <InputNumber source={ECompaniesFields.company_id} />
        <InputNumber source={ECompaniesFields.trade_id} />
        <InputNumber source={ECompaniesFields.reference_id} />
        <InputText source={ECompaniesFields.reference_custom_value} />
        <InputNumber source={ECompaniesFields.latitude} />
        <InputNumber source={ECompaniesFields.longitude} />
        <InputNumber source={ECompaniesFields.visits_counter} />
        <InputNumber source={ECompaniesFields.potential_search_counter} />
        <InputNumber source={ECompaniesFields.contact_counter} />
        <InputText source={ECompaniesFields.logo} />
        <InputText source={ECompaniesFields.logo_dir} />
        <InputNumber source={ECompaniesFields.max_recommended} />
        <InputNumber source={ECompaniesFields.max_promoted} />
        <InputText source={ECompaniesFields.factory_post_code} />
        <InputText source={ECompaniesFields.factory_post_seat} />
        <InputText source={ECompaniesFields.factory_street} />
        <InputText source={ECompaniesFields.factory_street_number} />
        <InputText source={ECompaniesFields.factory_locale_number} />
        <InputText source={ECompaniesFields.factory_voivodeship} />
        <InputText source={ECompaniesFields.factory_phone} />
        <InputText source={ECompaniesFields.factory_email} />
        <InputText source={ECompaniesFields.factory_www} />
        <InputBoolean source={ECompaniesFields.is_factory_address} />
        <InputText source={ECompaniesFields.slug_url} />
        <InputText source={ECompaniesFields.slug_url_old} />
        <InputText source={ECompaniesFields.seo_text_meta} />
        <InputText source={ECompaniesFields.seo_text_html} />
        <InputText source={ECompaniesFields.seo_title} />
        <InputBoolean source={ECompaniesFields.is_public_email} />
        <InputBoolean source={ECompaniesFields.plus_group} />
        <InputBoolean source={ECompaniesFields.seo_section_visible} />
        <InputBoolean source={ECompaniesFields.seo_section_for_edit} />
        <InputBoolean source={ECompaniesFields.is_complete} />
        <InputBoolean source={ECompaniesFields.show_similar} />
        <InputBoolean source={ECompaniesFields.is_similar_opened} />
        <InputBoolean source={ECompaniesFields.is_archival} />
        <InputBoolean source={ECompaniesFields.is_r_account} />
        <InputBoolean source={ECompaniesFields.is_internet_company_card} />
        <InputBoolean source={ECompaniesFields.is_trial} />
        <InputBoolean source={ECompaniesFields.is_cluster_mt} />
        <InputBoolean source={ECompaniesFields.show_in_search} />
        <InputBoolean source={ECompaniesFields.is_enabled_seo} />
        <InputBoolean source={ECompaniesFields.block_google_index} />
        <InputBoolean source={ECompaniesFields.is_limited} />
      </Form>
    </Edit>
  )
}

export const companies: ResourceProps = {
  name: EResources.companies,
  list: CompaniesList,
  show: CompaniesShow,
  edit: CompaniesEdit,
}
