export enum EAppReviewsFields {
  id = 'id',
  company_id = 'company_id',
  author_id = 'author_id',
  position = 'position',
  review = 'review',
  reply = 'reply',
  rating = 'rating',
  replied_at = 'replied_at',
  created_at = 'created_at',
  updated_at = 'updated_at',
}
