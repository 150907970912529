import { ReferenceInput, AutocompleteInput } from 'react-admin'
import { EResources } from 'src/types/resource.types'
import { ECompaniesFields } from 'src/lists/Companies/Companies.type'

export const CompanyReferenceInput = () => {
  return (
    <ReferenceInput
      reference={EResources.companies}
      source={ECompaniesFields.reference}
      perPage={200}
    >
      <AutocompleteInput
        filterToQuery={(searchText) => ({
          [ECompaniesFields.name]: searchText,
        })}
        fullWidth={true}
        isRequired={true}
        label='Company'
        source={ECompaniesFields.name}
        optionText={(record) =>
          record.name ||
          (record.nip && `NIP: ${record.nip}`) ||
          `ID: ${record.id}`
        }
      />
    </ReferenceInput>
  )
}
