import {
  BooleanField,
  Create,
  Datagrid,
  Edit,
  ImageField,
  ImageInput,
  ResourceProps,
  TextField,
} from 'react-admin'
import { EResources } from '../../types/resource.types'
import { EPartnersFields } from './Partners.type'
import { InputBoolean, InputText } from '../../components/Input'

import ChangeImage from './components/ChangeImage'
import Form from '../../components/Form/Form'
import { EMethod } from '../../hooks/useShowApiErrors'
import MyList from '../../components/MyList/MyList'

const PartnersList = () => (
  <MyList hasCreate={true}>
    <Datagrid rowClick='edit'>
      <TextField source={EPartnersFields.id} />
      <ImageField source={EPartnersFields.image_url} />
      <TextField source={EPartnersFields.link} />
      <BooleanField source={EPartnersFields.active} />
    </Datagrid>
  </MyList>
)

const PartnersEdit = () => (
  <Edit>
    <Form method={EMethod.PUT} resource={EResources.partners}>
      <ChangeImage />
      <InputText source={EPartnersFields.link} />
      <InputBoolean source={EPartnersFields.active} />
    </Form>
  </Edit>
)

const PartnersCreate = () => (
  <Create>
    <Form method={EMethod.POST} resource={EResources.partners}>
      <ImageInput source={EPartnersFields.file}>
        <ImageField source={'src'} title='title' />
      </ImageInput>
      <InputText source={EPartnersFields.link} />
      <InputBoolean source={EPartnersFields.active} />
    </Form>
  </Create>
)

export const partners: ResourceProps = {
  name: EResources.partners,
  list: PartnersList,
  edit: PartnersEdit,
  create: PartnersCreate,
}
