import { TextInput } from 'react-admin'
import { RED_SLUGS } from './InputText.const'

interface IInputTextProps {
  source: string
  label?: string
}

const InputText = ({ source, label }: IInputTextProps) => {
  const hasSlugInSource = RED_SLUGS.some((redSlug) => redSlug === source)

  return (
    <TextInput
      sx={{
        '& .MuiInputBase-root': {
          background: hasSlugInSource ? '#ef9a9a' : '',
        },
      }}
      multiline
      fullWidth
      name={source}
      source={source}
      label={label}
    />
  )
}

export { InputText }
