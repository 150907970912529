import { SimpleForm, useRecordContext } from 'react-admin'
import { ReactNode } from 'react'
import { EMethod, useShowApiErrors } from '../../hooks/useShowApiErrors'
import { EResources } from '../../types/resource.types'

const Form = ({
  children,
  resource,
  method,
}: {
  children: ReactNode
  resource: EResources
  method: EMethod
}) => {
  const record = useRecordContext()

  const id = record ? +record.id : null

  const { save } = useShowApiErrors(resource, method, id)

  return <SimpleForm onSubmit={save}>{children}</SimpleForm>
}

export default Form
