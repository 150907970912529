export enum ECompaniesReviewsFields {
  id = 'id',
  company_id = 'company_id',
  author_company_id = 'author_company_id',
  author_id = 'author_id',
  position = 'position',
  review = 'review',
  reply = 'reply',
  rating_1 = 'rating_1',
  rating_2 = 'rating_2',
  rating_3 = 'rating_3',
  rating_4 = 'rating_4',
  avg_rating = 'avg_rating',
  replied_at = 'replied_at',
  created_at = 'created_at',
  updated_at = 'updated_at',
}
