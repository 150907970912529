import {
  Datagrid,
  DateField,
  Edit,
  ResourceProps,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin'
import { EResources } from '../../types/resource.types'
import { ECompaniesReviewsFields } from './CompaniesReviews.type'
import { InputDate, InputText } from '../../components/Input'
import Form from '../../components/Form/Form'
import { EMethod } from '../../hooks/useShowApiErrors'
import MyList from '../../components/MyList/MyList'

const CompaniesReviewsList = () => (
  <MyList>
    <Datagrid rowClick={'show'}>
      <TextField source={ECompaniesReviewsFields.id} />
      <TextField source={ECompaniesReviewsFields.author_id} />
      <TextField source={ECompaniesReviewsFields.company_id} />
      <TextField source={ECompaniesReviewsFields.author_company_id} />
      <TextField source={ECompaniesReviewsFields.review} />
      <TextField source={ECompaniesReviewsFields.rating_1} />
      <TextField source={ECompaniesReviewsFields.rating_2} />
      <TextField source={ECompaniesReviewsFields.rating_3} />
      <TextField source={ECompaniesReviewsFields.rating_4} />
      <TextField source={ECompaniesReviewsFields.avg_rating} />
      <TextField source={ECompaniesReviewsFields.position} />
      <TextField source={ECompaniesReviewsFields.reply} />
      <DateField source={ECompaniesReviewsFields.created_at} />
      <DateField source={ECompaniesReviewsFields.updated_at} />
      <DateField source={ECompaniesReviewsFields.replied_at} />
    </Datagrid>
  </MyList>
)

const CompaniesReviewsShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source={ECompaniesReviewsFields.id} />
      <TextField source={ECompaniesReviewsFields.author_id} />
      <TextField source={ECompaniesReviewsFields.company_id} />
      <TextField source={ECompaniesReviewsFields.author_company_id} />
      <TextField source={ECompaniesReviewsFields.review} />
      <TextField source={ECompaniesReviewsFields.rating_1} />
      <TextField source={ECompaniesReviewsFields.rating_2} />
      <TextField source={ECompaniesReviewsFields.rating_3} />
      <TextField source={ECompaniesReviewsFields.rating_4} />
      <TextField source={ECompaniesReviewsFields.avg_rating} />
      <TextField source={ECompaniesReviewsFields.position} />
      <TextField source={ECompaniesReviewsFields.reply} />
      <DateField source={ECompaniesReviewsFields.created_at} />
      <DateField source={ECompaniesReviewsFields.updated_at} />
      <DateField source={ECompaniesReviewsFields.replied_at} />
    </SimpleShowLayout>
  </Show>
)

const CompaniesReviewsEdit = () => (
  <Edit>
    <Form resource={EResources.companiesReviews} method={EMethod.PUT}>
      <InputText source={ECompaniesReviewsFields.id} />
      <InputText source={ECompaniesReviewsFields.author_id} />
      <InputText source={ECompaniesReviewsFields.company_id} />
      <InputText source={ECompaniesReviewsFields.author_company_id} />
      <InputText source={ECompaniesReviewsFields.review} />
      <InputText source={ECompaniesReviewsFields.rating_1} />
      <InputText source={ECompaniesReviewsFields.rating_2} />
      <InputText source={ECompaniesReviewsFields.rating_3} />
      <InputText source={ECompaniesReviewsFields.rating_4} />
      <InputText source={ECompaniesReviewsFields.avg_rating} />
      <InputText source={ECompaniesReviewsFields.position} />
      <InputText source={ECompaniesReviewsFields.reply} />
      <InputDate source={ECompaniesReviewsFields.created_at} />
      <InputDate source={ECompaniesReviewsFields.updated_at} />
      <InputDate source={ECompaniesReviewsFields.replied_at} />
    </Form>
  </Edit>
)

export const companiesReviews: ResourceProps = {
  name: EResources.companiesReviews,
  options: { label: 'Companies reviews' },
  list: CompaniesReviewsList,
  show: CompaniesReviewsShow,
  edit: CompaniesReviewsEdit,
}
