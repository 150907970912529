import { FunctionField } from 'react-admin'
import { DEFAULT_TRUNCATE_LIMIT } from './TruncatedTextField.const'

interface ITruncatedTextFieldProps {
  source: string
  limit?: number
}

const TruncatedTextField = ({
  source,
  limit = DEFAULT_TRUNCATE_LIMIT,
}: ITruncatedTextFieldProps) => {
  return (
    <FunctionField
      source={source}
      render={(record) => {
        const str = record[source]
        if (!str) return str
        return str.length > 20 ? str.slice(0, limit) + '...' : str
      }}
    />
  )
}

export { TruncatedTextField }
