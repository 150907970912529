import {
  Create,
  Datagrid,
  Edit,
  NumberField,
  ResourceProps,
  TextField,
} from 'react-admin'
import { EPagesFields } from './Pages.type'
import { InputNumber, InputText, InputWysiwyg } from 'src/components/Input'
import { TruncatedTextField } from 'src/components/Field'
import { EResources } from 'src/types/resource.types'
import Form from '../../components/Form/Form'
import { EMethod } from '../../hooks/useShowApiErrors'
import MyList from '../../components/MyList/MyList'

const PagesList = () => (
  <MyList hasCreate={true}>
    <Datagrid rowClick='edit'>
      <TextField source={EPagesFields.id} />
      <TextField source={EPagesFields.name} />
      <TextField source={EPagesFields.title} />
      <NumberField source={EPagesFields.page_order} />
      <TruncatedTextField source={EPagesFields.text} />
    </Datagrid>
  </MyList>
)

const PagesEdit = () => (
  <Edit>
    <Form resource={EResources.pages} method={EMethod.PUT}>
      <InputText source={EPagesFields.id} />
      <InputText source={EPagesFields.name} />
      <InputText source={EPagesFields.title} />
      <InputNumber source={EPagesFields.page_order} />
      <InputWysiwyg source={EPagesFields.text} />
    </Form>
  </Edit>
)

const PagesCreate = () => (
  <Create>
    <Form resource={EResources.pages} method={EMethod.POST}>
      <InputText source={EPagesFields.name} />
      <InputText source={EPagesFields.title} />
      <InputNumber source={EPagesFields.page_order} />
      <InputWysiwyg source={EPagesFields.text} />
    </Form>
  </Create>
)

export const pages: ResourceProps = {
  name: EResources.pages,
  list: PagesList,
  edit: PagesEdit,
  create: PagesCreate,
}
