import { useRecordContext } from 'react-admin'
import { InputNumber } from '../../../../components/Input'
import { EAccountTypePermissionFields } from '../../AccountTypePermissions.type'

const DynamicInput = () => {
  const record = useRecordContext()

  const renderInput = () => {
    if (record.permission_type === 'INTEGER') {
      return <InputNumber source={EAccountTypePermissionFields.value} />
    }
  }

  return renderInput()
}

export default DynamicInput
