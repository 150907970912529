import { DateInput } from 'react-admin'

interface IInputDateProps {
  source: string
}

const InputDate = ({ source }: IInputDateProps) => {
  return <DateInput name={source} source={source} />
}

export { InputDate }
