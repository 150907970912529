export enum EResources {
  pages = 'pages',
  users = 'users',
  grupas = 'grupas',
  grupakats = 'grupakats',
  companies = 'companies',
  ads = 'ads',
  references = 'references',
  contacts = 'contacts',
  roles = 'roles',
  offers = 'offers',
  offerStatuses = 'offerStatuses',
  systemConfigs = 'systemConfigs',
  postalCodes = 'postal_codes',
  redirects = 'redirects',
  partners = 'partners',
  accountTypes = 'accountTypes',
  accountTypePermissions = 'accountTypePermissions',
  voivodeships = 'voivodeships',
  appReviews = 'appReviews',
  companiesReviews = 'reviews',
  inquiries = 'inquiries',
  positions = 'positions',
  permissions = 'permissions',
}
