import {
  BooleanField,
  Create,
  Datagrid,
  Edit,
  ResourceProps,
  TextField,
} from 'react-admin'
import { EContactsFields } from './Contacts.type'
import { InputBoolean, InputText } from 'src/components/Input'
import { TruncatedTextField } from 'src/components/Field'
import { EResources } from 'src/types/resource.types'
import Form from '../../components/Form/Form'
import { EMethod } from '../../hooks/useShowApiErrors'
import MyList from '../../components/MyList/MyList'

const ContactsList = () => (
  <MyList hasCreate={true}>
    <Datagrid rowClick='edit'>
      <TextField source={EContactsFields.id} />
      <TextField source={EContactsFields.email} />
      <TruncatedTextField source={EContactsFields.message} />
      <TextField source={EContactsFields.name} />
      <TextField source={EContactsFields.subject} />
      <BooleanField source={EContactsFields.terms} />
    </Datagrid>
  </MyList>
)

const ContactsEdit = () => (
  <Edit>
    <Form method={EMethod.PUT} resource={EResources.contacts}>
      <InputText source={EContactsFields.id} />
      <InputText source={EContactsFields.email} />
      <InputText source={EContactsFields.message} />
      <InputText source={EContactsFields.name} />
      <InputText source={EContactsFields.subject} />
      <InputBoolean source={EContactsFields.terms} />
    </Form>
  </Edit>
)

const ContactsCreate = () => (
  <Create>
    <Form method={EMethod.POST} resource={EResources.contacts}>
      <InputText source={EContactsFields.email} />
      <InputText source={EContactsFields.message} />
      <InputText source={EContactsFields.name} />
      <InputText source={EContactsFields.subject} />
      <InputBoolean source={EContactsFields.terms} />
    </Form>
  </Create>
)

export const contacts: ResourceProps = {
  name: EResources.contacts,
  list: ContactsList,
  create: ContactsCreate,
  edit: ContactsEdit,
}
